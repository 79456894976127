



























































































import { OccupancyService } from "@/service";
import { Component, Vue } from "vue-property-decorator";
import { BaseComponent } from "@/base/BaseComponent";
import firebase from "firebase";

@Component
export default class Home extends BaseComponent {
  isAdmin: boolean = false;
  isReader: boolean = false;

  created() {
    firebase.auth().onAuthStateChanged((userAuth) => {
      if (userAuth) {
        firebase
          .auth()
          .currentUser?.getIdTokenResult()
          .then((result) => {
            console.log(result.claims);
            this.isAdmin = result.claims.role == "admin";
            this.isReader = result.claims.role == "reader";
          });
      }
    });
  }

  redirectEntryExitPage(pageType: string) {
    this.$router.push("/" + pageType);
  }

  settings() {
    this.$router.push("/settings");
  }

  users() {
    this.$router.push("/users");
  }

  get totalCounts() {
    return this.$store.getters.count;
  }

  get maxAllowed() {
    return this.$store.getters.maxOccupancy;
  }

  get color() {
    if (this.totalCounts > 0) {
      if (this.totalCounts >= this.maxAllowed) return "red";

      if (this.totalCounts >= this.maxAllowed * 0.8) return "orange";
    }

    return "green";
  }

}
