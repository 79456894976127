import { Vue } from "vue-property-decorator";
import firebase from "firebase";


export class BaseComponent extends Vue {
    get currentUser(): string {
        const user = firebase.auth().currentUser as any;

        return user ? user.email : "";
    }

    confirm(title: string, message: string) {
        const root: any = this.$root;

        return root.$confirm(title, message);
    }

    refresh() {
        this.$store.dispatch("getOccupancy");
    }
}