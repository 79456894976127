import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router';
import LoginPage from '@/views/Login/Index.vue'
import HomePage from '@/views/Home/Index.vue'
import Entry from '@/views/Entry/Index.vue';
import Exit from '@/views/Exit/Index.vue';
import Settings from '@/views/Settings/Index.vue';
import Users from '@/views/Users/Index.vue';
import firebase from 'firebase';

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '*',
    redirect: '/login'
  },
  {
    path: '/',
    redirect: '/login'
  },
  {
    path: '/login',
    name: 'Login',
    component: LoginPage
  },
  {
    path: '/home',
    name: 'Home',
    component: HomePage,
    meta: { requiresAuth: true }
  },
  {
    path: '/entry',
    name: 'Entry',
    component: Entry,
    meta: { requiresAuth: true }
  },
  {
    path: '/exit',
    name: 'Exit',
    component: Exit,
    meta: { requiresAuth: true }
  },
  {
    path: '/settings',
    name: 'Settings',
    component: Settings,
    meta: { requiresAuth: true }
  }
  ,
  {
    path: '/users',
    name: 'Users',
    component: Users,
    meta: { requiresAuth: true }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router

router.beforeEach((to, from, next) => {
  const currentUser = firebase.auth().currentUser;
  const requireAuth = to.matched.some(record => record.meta.requiresAuth);

  if (requireAuth && !currentUser)
    next('login')
  else if (!requireAuth && currentUser)
    next('home')
  else
    next()
});