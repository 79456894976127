



























































































import { EntryRequestModel, LogRequestModel, OccupancyLogModel } from "@/model";
import { OccupancyService } from "@/service";
import { Component, Vue } from "vue-property-decorator";
import { BaseComponent } from "@/base/BaseComponent";

@Component
export default class Entry extends BaseComponent {
  details: boolean = false;
  gridLoading: boolean = false;
  progress: boolean = false;

  pageSize: number = 100;

  public addEntry(count: number) {
    const service = new OccupancyService();

    const request = new EntryRequestModel();
    request.incrementBy = count;
    request.updatedBy = this.currentUser;

    this.progress = true;
    service.addEntry(request).then((response) => {
      this.$store.dispatch("getOccupancy");
      this.progress = false;
    });
  }

  public deleteItem(item: OccupancyLogModel) {
    this.confirm("Delete", `Are you sure you want to revert this entry?`).then(
      (confirm: any) => {
        if (confirm) {
          const service = new OccupancyService();

          service.deleteItem(item.id).then(() => {
            this.$store.dispatch("getOccupancy");

            this.details = false;
          });
        }
      }
    );
  }

  refresh() {
    super.refresh();

    if (this.details)
      this.getLogs();
  }

  get totalCounts() {
    return this.$store.getters.count;
  }

  get maxAllowed() {
    return this.$store.getters.maxOccupancy;
  }

  public redirectToHome() {
    this.$router.push("/home");
  }

  logs: Array<OccupancyLogModel> = [];
  toggle() {
    this.details = !this.details;

    if (this.details) {
      this.getLogs();
    }
  }

  getLogs() {
    const service = new OccupancyService();

      this.logs = [];
      this.gridLoading = true;
      service.getLogs(new LogRequestModel(), 1).then((data) => {
        this.logs = data.filter((d) => d.change > 0);
        this.gridLoading = false;
      });
  }

  headers: any = [
    {
      text: "Time",
      align: "start",
      sortable: false,
      value: "lastUpdated",
    },
    { text: "Entered By", value: "updatedBy", sortable: false },
    { text: "Count", value: "change", sortable: false },
    { text: "", value: "actions", sortable: false },
  ];

  get color() {
    if (this.totalCounts > 0) {
      if (this.totalCounts >= this.maxAllowed) return "red";

      if (this.totalCounts >= this.maxAllowed * 0.8) return "orange";
    }

    return "green";
  }
}
