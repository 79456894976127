
































import { Component, Vue } from "vue-property-decorator";
import firebase from "firebase";
import moment from "moment";
import axios from "axios";
import { BaseComponent } from "@/base/BaseComponent";
import AppConfirm from "@/base/AppConfirm.vue";

@Component({
  components: { AppConfirm },
})
export default class App extends BaseComponent {
  polling: any = null;

  created() {
    axios.defaults.headers.common["APIKey"] = `PKaRSChfdz`;

    this.createFilters();
    this.$store.dispatch("getOccupancy");

    this.polling = setInterval(() => {
      this.$store.dispatch("getOccupancy");
    }, 3000);
  }

  mounted() {
    const root: any = this.$root;
    const confirm: AppConfirm = this.$refs.confirm as AppConfirm;

    root.$confirm = confirm.show;
  }

  beforeDestroy() {
    clearInterval(this.polling);
  }

  logout() {
    firebase
      .auth()
      .signOut()
      .then(() => {
        this.$router.replace("login");
      });
  }

  createFilters() {
    Vue.filter("errorMessages", (value: any, name: string) => {
      return this.$vuehelper.val.messages(value, name);
    });

    Vue.filter("dateDisplay", (value: any) => {
      if (!value) return "";

      const date = moment.utc(value);
      const localDate = moment(date).local();

      return localDate.format("DD/MM/YYYY HH:mm A");
    });
  }
}
