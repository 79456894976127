import axios from 'axios';
import { AxiosResponse, AxiosRequestConfig, ResponseType } from 'axios'
import { stringify } from 'qs';

import { Settings } from '@/config';

export abstract class ServiceHelper {
    protected apiUrl: string = Settings.ApiUrl;

    httpGet(route: string, request: any, responseType?: ResponseType): Promise<AxiosResponse<any>> {
        const params: any = {};
        if (request) {
            for (const key in request) {
                if (key.charAt(0) != "_") {
                    if (request[key] instanceof Date) {
                        const dateString: any = request[key];

                        const date: Date = new Date(dateString);

                        if (date)
                            params[key] = `${date.getFullYear()}-${("0" + (date.getMonth() + 1)).slice(-2)}-${("0" + date.getDate()).slice(-2)}`;
                    }
                    else {
                        const value: any = request[key];

                        if (value || value === false)
                            params[key] = request[key];
                    }
                }
            }
        }

        const path = `${this.apiUrl}/${route}`;
        const config: AxiosRequestConfig = {
            params: params,
            'paramsSerializer': params => {
                return stringify(params, { arrayFormat: 'repeat' })
            }
        };

        if (responseType) config.responseType = responseType;

        return axios.get<any>(path, config);
    }


    protected httpPost(route: string, data: any): Promise<AxiosResponse<any>> {
        const path = `${this.apiUrl}/${route}`;

        return axios.post(path, data);
    }

    protected httpPut(route: string, data: any): Promise<AxiosResponse<any>> {
        const path = `${this.apiUrl}/${route}`;

        return axios.put(path, data);
    }

    protected httpDelete(route: string): Promise<AxiosResponse<any>> {
        const path = `${this.apiUrl}/${route}`;

        return axios.delete(path);
    }
}