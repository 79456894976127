
































































import { Component, Vue } from "vue-property-decorator";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import firebase from "firebase";

@Component({
  mixins: [validationMixin],
  validations: {
    email: { required },
    password: { required },
  },
})
export default class Login extends Vue {
  public email: string = "";
  public password: string = "";
  public showPassword: boolean = false;
  public errorMessage: string = "";
  public accessDenied: boolean = false;  

  login() {
    this.errorMessage = "";
    this.accessDenied = false;

    this.$v.$touch();
    if (!this.$v.$invalid) {
      firebase
        .auth()
        .signInWithEmailAndPassword(this.email, this.password)
        .then((result) => {
          this.afterLogin(this.email);
        })
        .catch((err) => {
          this.errorMessage = err.message;
        });
    } else {
      this.errorMessage = "Please enter valid credentials";
    }
  }

  afterLogin(email: string) {
    const db = firebase.firestore();
    const usersCollection = db.collection("users");

    usersCollection
      .doc(email.toLowerCase())
      .get()
      .then((v) => {
        if (v.exists) this.$router.push("/home");
        else {
          firebase
            .auth()
            .signOut()
            .then(() => {
              this.accessDenied = true;
            });
        }
      });
  }

  googleLogin() {
    this.accessDenied = false;
    this.errorMessage = "";

    const provider = new firebase.auth.GoogleAuthProvider();

    firebase
      .auth()
      .signInWithPopup(provider)
      .then((result) => {
        let email: any = "";
        if (result && result.user) email = result.user.email?.toString();

        this.afterLogin(email);
      })
      .catch((err) => {
        console.log(err);
      });
  }
}
