import { EntryRequestModel, ExitRequestModel, LogRequestModel, OccupancyLogModel, OccupancyModel } from "@/model";
import { ServiceHelper } from "./base.service";

export class OccupancyService extends ServiceHelper {
    path: string = "occupancy";

    get(): Promise<OccupancyModel> {
        return this.httpGet(this.path, null).then(response => {
            return response.data;
        });
    }

    getLogs(request: LogRequestModel, type: number): Promise<Array<OccupancyLogModel>> {
        return this.httpGet(`${this.path}/log/${request.limit ? request.limit : 50}/${type}`, null).then(response => {
            return response.data;
        });
    }

    addEntry(request: EntryRequestModel) {
        return this.httpPost(`${this.path}/entry`, request).then(response => {
            return response.data;
        })
    }

    deleteItem(id: string) {
        return this.httpDelete(`${this.path}/log/${id}`).then(response => {
            return response.data;
        })
    }

    addExit(request: ExitRequestModel) {
        return this.httpPost(`${this.path}/exit`, request).then(response => {
            return response.data;
        })
    }

    configure(maxOccupancy: number) {
        return this.httpPut(`${this.path}/configure`, { "maxOccupancy": maxOccupancy }).then(response => {
            return response.data;
        })
    }

    updateCount(count: number, updatedBy: string) {
        return this.httpPut(`${this.path}/update`, { "updateTo": count, "updateBy": updatedBy }).then(response => {
            return response.data;
        })
    }
}