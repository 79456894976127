



































































































import { Component, Vue } from "vue-property-decorator";
import { BaseComponent } from "@/base/BaseComponent";
import firebase from "firebase";
import { validationMixin } from "vuelidate";
import { required, email } from "vuelidate/lib/validators";

@Component({
  mixins: [validationMixin],
  validations: {
    editedItem: {
      email: { required, email },
      role: { required },
    },
  },
})
export default class Users extends BaseComponent {
  headers: any = [
    {
      text: "Email",
      align: "start",
      sortable: false,
      value: "email",
    },
    { text: "Role", value: "role", sortable: false },
    { text: "", value: "actions", sortable: false },
  ];

  roles: any = ["admin", "user"];

  pageSize: number = 100;
  dialog: boolean = false;
  editedItem: any = {};

  users: Array<any> = [];

  created() {
    this.loadUsers();

    firebase.auth().onAuthStateChanged((userAuth) => {
      if (userAuth) {
        firebase.auth().currentUser?.getIdTokenResult().then(result => {
          if(result.claims.role !== "admin")
            this.redirectToHome();
        })
      }
    });
  }

  close() {
    this.dialog = false;

    this.editedItem = {};
  }

  editItem(item: any) {
    this.editedItem = {};
    this.editedItem.id = item.id;
    this.editedItem.email = item.email;
    this.editedItem.role = item.role;

    this.dialog = true;
  }

  deleteItem(item: any) {
    this.confirm(
      "Delete",
      `Are you sure you want to remove '${item.email}'?`
    ).then((confirm: any) => {
      if (confirm) {
        firebase
          .firestore()
          .collection("users")
          .doc(item.email)
          .delete()
          .then(() => {
            this.loadUsers();
          });
      }
    });
  }

  save() {
    this.$v.$touch();
    if (!this.$v.$invalid) {
      firebase
        .firestore()
        .collection("users")
        .doc(this.editedItem.email.toLowerCase())
        .set({ Role: this.editedItem.role })
        .then(() => {
          this.loadUsers();

          this.close();
        });
    }
  }

  loadUsers() {
    this.users = [];
    firebase
      .firestore()
      .collection("users")
      .get()
      .then((snap) => {
        snap.forEach((doc) => {
          const user = doc.data();
          user.id = doc.id;
          user.email = doc.id;
          user.role = doc.data().Role;

          this.users.push(user);
        });
      });
  }

  redirectToHome() {
    this.$router.push("/home");
  }
}
