import { Validation } from "vuelidate";
import { ValidationEvaluation, ValidationGroups } from 'vue/types/vue';

class ValHelper {
    messages(evaluation: ValidationEvaluation, label: string) {
        const errors: Array< string> = [];

        if (!evaluation!.$dirty || !evaluation!.$invalid) return errors;

        if (!evaluation!.required) errors.push(`Required`);

        if (!evaluation!.invalid) errors.push(``);

        return errors;
    }


}

export class VueHelperProvider {
    public val: ValHelper = new ValHelper();
    clone<T>(object: T): T {
        return this._clone(object);
    }

    getImageUrl(blobText: string) {
        return `data:image/png;base64, ${blobText}`
    }

    private _clone(object: any) {
        const cloned: any = new (object as any).constructor();

        for (const key in object) {
            try {
                cloned[key] = object[key];

                if (typeof object[key] == "object")
                    cloned[key] = this._clone(object[key]);
            }
            catch (e) { //error  
            }
        }
        return cloned;
    }
}


