import { GetterTree, MutationTree, ActionTree } from 'vuex';

import { OccupancyState, OccupancyModel } from '@/model';
import { OccupancyService } from '@/service';

const state: OccupancyState = {
    count: 0,
    maxOccupancy: 0
}

const getters: GetterTree<OccupancyState, any> = {
    count: state => {
        return state.count;
    },
    maxOccupancy: state => {
        return state.maxOccupancy;
    }
}

const mutations: MutationTree<OccupancyState> = {
    setOccupancy(state: OccupancyState, data: OccupancyModel) {
        state.count = data.count;
        state.maxOccupancy = data.maxOccupancy;
    }
}

const actions: ActionTree<OccupancyState, any> = {
    getOccupancy(context) {
        const service = new OccupancyService();

        return service.get().then(data => {
            context.commit('setOccupancy', data);
        });
    }
}

export const AppModule = {
    state,
    getters,
    mutations,
    actions
}