export class OccupancyModel {
    count: number;
    maxOccupancy: number;
    lastUpdated: Date;
    updatedBy: string;
}

export class OccupancyLogModel {
    id: string;
    change: number;
    count: number;
    lastUpdated: Date;
    updatedBy: string;
}

export class LogRequestModel {
    limit: number;
}

export class EntryRequestModel {
    incrementBy: number;
    updatedBy: string;
}

export class ExitRequestModel {
    decrementBy: number;
    updatedBy: string;
}