

























































































import { OccupancyService } from "@/service";
import { Component, Vue } from "vue-property-decorator";
import { BaseComponent } from "@/base/BaseComponent";
import firebase from "firebase";

@Component
export default class Settings extends BaseComponent {
  count: number = 0;
  maxOccupancy: number = 0;

  created() {
    this.count = this.$store.getters.count;
    this.maxOccupancy = this.$store.getters.maxOccupancy;

    firebase.auth().onAuthStateChanged((userAuth) => {
      if (userAuth) {
        firebase.auth().currentUser?.getIdTokenResult().then(result => {
          if(result.claims.role !== "admin")
            this.redirectToHome();
        })
      }
    });
  }

  public redirectToHome() {
    this.$router.push("/home");
  }

  updateMaxOccupancy() {
    const service = new OccupancyService();

    service.configure(this.maxOccupancy).then((response) => {
      this.$store.dispatch("getOccupancy");

      this.$router.push("/home");
    });
  }

  updateCount() {
    const service = new OccupancyService();

    service.updateCount(this.count, this.currentUser).then((response) => {
      this.$store.dispatch("getOccupancy");

      this.$router.push("/home");
    });
  }
}
