import Vue from 'vue'
import Vuelidate from 'vuelidate';

import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import VueHelper from './plugins/vue-helper';
import App from './App.vue'
import firebase from 'firebase';

import './registerServiceWorker'
import './styles/global.scss';


Vue.config.productionTip = false;

Vue.use(VueHelper);
Vue.use(Vuelidate);

const firebaseConfig = {
  apiKey: "AIzaSyCf09rcW6Bb_Rprwv7vlnB5LaFY0bYFGIk",
  authDomain: "otracker.yectra.com",
  projectId: "yectra-tracker",
  storageBucket: "yectra-tracker.appspot.com",
  messagingSenderId: "122203901059",
  appId: "1:122203901059:web:de52a18aea182a52b89fb6",
  measurementId: "G-VQPYKE4G85"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

let app: any = undefined;

firebase.auth().onAuthStateChanged(() => {
  if (!app) {
    app = new Vue({
      router,
      store,
      vuetify,
      render: h => h(App)
    }).$mount('#app');
  }
});