
















import { Component, Vue } from "vue-property-decorator";

@Component
export default class AppConfirm extends Vue {
  dialog: boolean = false;
  message: string = "";
  resolve: any = null;
  reject: any = null;
  title: string = "";

  show(title: string, message: string): Promise<boolean> {
    this.dialog = true;
    this.title = title;
    this.message = message;

    return new Promise((resolve, reject) => {
      this.resolve = resolve;
      this.reject = reject;
    });
  }

  agree() {
    this.resolve(true);
    this.dialog = false;
  }

  cancel() {
    this.resolve(false);
    this.dialog = false;
  }
}
